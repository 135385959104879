/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

html, body, mat-sidenav-container, .app-container
{
  margin: 0;
  width: 100%;
  height: 100%;
}

market-app
{
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

a:visited, a:link
{
  text-decoration: none;
  color: #428bca;
}
a:hover
{
  color: #428bca;
  text-decoration: underline;
}

.content
{
    margin: 16px;
}

.text-muted
{
    color: #999;
    font-size: 14px;
}

.text-fixed
{
    width: 20px;
    text-align: center;
}

.center
{
    text-align: center;
}

.nowrap
{
    display: inline-block;
}

.full-width
{
    width: 100%;
}

/* dialogs */

.mat-mdc-dialog-surface
{
    min-width: 500px !important;
    max-width: 900px;
}

.mat-mdc-dialog-actions
{
    justify-content: space-evenly !important;
}

/* forms */

.mat-mdc-form-field
{
    width: 100%;
    max-width: none;
}

/* Toolbars */

.app-toolbar-filler
{
  flex: 1 1 auto;
}

/* tables */
th 
{
    vertical-align: middle;
}
td 
{
    vertical-align: top;
}
.mat-table
{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
}
.action-edit
{
    /*color: #E9932B;*/
    color: #673ab7;
}
.action-attach
{
    color: #673ab7;
}
.action-delete
{
    color: #D1282B;
}
.page-buttons 
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-buttons > button 
{
    margin: 5px;
}

/* Avatar images */
.avatar
{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    flex-shrink: 0;
    vertical-align: middle;
}
.avatar64
{
    height: 64px;
    width: 64px;
    border-radius: 50%;
    flex-shrink: 0;
}

/* Advanced View */
.terminal
{
    background-color: #000000;
}

.terminal-text
{
    color: greenyellow;
    font-family: Courier New, Courier, monospace;
    font-size: 18px;
}


